@import './../../../../theme/theme';

.mainNavContainer{
  height: 80px;
  width: 100%;
  background-color: #f8f9fa!important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
  z-index: 1030;
  position: fixed;
  top: 0;
}
.top_nav {
  justify-content: center;
  width: 100%;
  height: 80px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 1660px;
  z-index: 1030;
  position: relative;
}

.brandLogos{
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 40px;
  height: 60px;
  .tenantLogo{
    height: 60px;
  }
  .primaryTenantLogo,.hidePrimaryTenantLogo{
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .logo_divider {
    height: 36px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.5);
    margin: 0rem 0.5rem;
  }
}

.menuItems{
  display: flex;
  flex-direction: row;
  height: 100%;
  margin-left: -54px;
  .linkContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:10px;
    > p {
      font-family: $primaryFontFamily;
      font-weight: 14.24px;
      font-weight: 700;
      margin: 5px;
      line-height: 1;
    }
    > p.active_icon {
      color: $primaryColor;
    }
    .link_icon {
      width: 24px;
      height: 24px;
      margin: 5px;
    }
    &:hover{
      cursor: pointer;
      color: $primaryColor;
      > .link_icon > svg > path {
        fill: $primaryColor;
      }
    }
  }
}

@include smTabletView {
  .top_nav {
    justify-content: flex-end;
    .brandLogos{
      left: 20px;
      height: 48px;
      .tenantLogo {
        height: 48px;
      }
    }
  }
}

@include mdTabletView  {
  .top_nav {
    justify-content: center;
  }
}

@include xsPhoneView {
  .mainNavContainer{
    bottom: 0;
    top: auto;
  }
  .top_nav {
    display: flex !important;
    padding: 0;

    justify-content: space-between;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.12);
    .brandLogos{
      left: 20px;
      height: 48px;
      .hidePrimaryTenantLogo{
        display: none;
      }
      .tenantLogo {
        height: 48px;
      }
    }
  }
  .menuItems{
    margin-left: 0px;
    position: absolute;
    right: 20px;
    justify-content: right;
    .linkContainer{
      margin:0px;
      > p {
        font-size: 12px;
      }
      .link_icon {
        margin: 5px;
      }
    }
  }
}


