@import './../../../theme/theme';
@import './ProjectSnippet.module.scss';
@import './ProjectDetails.module.scss';

.container {
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 100vw;
  margin-top: 50vh;
  padding-bottom: 60px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $backgroundColor;
  border-radius: 13px 13px 0px 0px;
  height: 50px;
  width: 100%;
}

.headerText {
  margin: 0;
  padding-left: 20px;
  font-weight: 600;
}

.tabButtonContainer {
  flex-grow: 1;
  display: flex;
}

.tabButton {
  background-color: transparent;
  border: none;
  margin-left: 16px;
  margin-top: 18px;
}

.tabButton:hover {
  cursor: pointer;
}

.tabButtonText {
  font-family: $primaryFontFamily;
  font-size: 13px;
  font-weight: bold;
  color: $primaryFontColor;
  margin-bottom: 10px;
}

.tabButtonSelected {
  color: $primaryColor;
  font-family: $primaryFontFamily;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tabButtonSelectedIndicator {
  height: 3px;
  background-color: $primaryColor;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  align-self: flex-end;
}

.headerSearchMode {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $backgroundColor;
  border-radius: 13px 13px 0px 0px;
  width: 100%;
  height: 50px;
}

.searchIcon {
  margin-left: 6px;
  padding: 10px;
  font-size: 12px;
  margin-right: 6px;
}

.searchIcon:hover {
  cursor: pointer;
}

.searchInput {
  flex-grow: 3;
}

.cancelIcon {
  margin-right: 6px;
  padding: 10px;
}

.cancelIcon > svg {
  width: 18px;
}

.cancelIcon:hover {
  cursor: pointer;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  background-color: $backgroundColor;
  margin-top: 0px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  height: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  > div {
    margin-top: 20px;
    position: relative;
  }
}

.projectsContainer::-webkit-scrollbar {
  width: 6px;
}

.projectsContainer::-webkit-scrollbar-track {
  background-color: $dividerColor;
}

.projectsContainer::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
}

.popupProject {
  margin-top: 0px;
  cursor: pointer;
  z-index: 5;
  background-color: $backgroundColor;
}

.allProjectsContainer {
  padding-bottom: 12px;
}

.projectNotFound {
  font-family: $primaryFontFamily;
  text-align: center;
  padding: 0px 20px;
}

.projectNotFoundImage {
  width: 100%;
}

.avoidPointerEvents {
  display: block;
  height: 50vh;
  z-index: 999;
  // pointer-events: none;
}

.openMap {
  padding: 12px 20px;
  border-radius: 100px;
  background-color: $primaryColor;
  color: #fff;
  position: absolute;
  bottom: 90px;
  right: 0;
  left: 0;
  // max-width: 50px;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.openMap > svg {
  width: 20px;
  margin-right: 5px;
}

.dragBar {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  background-color: #c5c5c5;
  height: 5px;
  border-radius: 100px;
}

.modalContainer {
  background: rgba(0, 0, 0, 0.6);
}

@include smTabletView {
  .allProjectsContainer > div > div {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 16px;
  }

  .container {
    padding-bottom: 0px;
  }
}

@include mdTabletView {
  .mainContainer {
    max-height: calc(100vh - 60px);
    position: relative;
    // top: 60px;
    overflow-y: visible;
  }

  .container {
    overflow: visible;
    padding-top: 0px;
    margin-top: 110px;
    // padding-bottom: 0px;
    width: fit-content;
    // z-index: 2;
    bottom: 0px;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    z-index: 2;
  }

  // .container::-webkit-scrollbar {
  //   display: none;
  // }

  .allProjectsContainer > div > div {
    display: block;
  }
  .cardContainer {
    position: relative;
    top: 30px;
    left: 24px;
  }
  .projectsContainer {
    margin-top: 5px;
    width: 360px;
    overflow-y: hidden;
  }
  .header {
    width: 360px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
  }

  .headerSearchMode {
    width: 360px;
  }

  .avoidPointerEvents {
    display: none;
  }

  .openMap {
    display: none;
  }
}

:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}
