@import './../../../theme/theme';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// .modal::-webkit-scrollbar {
//     width: 0px;
// }

.projectEditBlock {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: white;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  z-index: 9;
  &:hover {
    cursor: pointer;
  }
}

.projectImage {
  height: 160px;
  width: 100%;
  background-color: $primaryColor;
  position: relative;
}

.projectImage:hover {
  cursor: pointer;
}

.projectImageFile {
  height: 160px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}

.projectImageBlock {
  position: absolute;
  bottom: 12px;
  left: 7px;
}

.projectType {
  font-family: $primaryFontFamily;
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.projectName {
  font-family: $primaryFontFamily;
  font-size: 18px;
  color: #fff;
}

/* Progress Bar */
.progressBar {
  height: 4px;
  width: 100%;
  background-color: $dividerColor;
}

.progressBarHighlight {
  height: 4px;
  background-color: $primaryColor;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.projectInfo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 10px;
}

.targetLocation {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.target {
  font-family: $primaryFontFamily;
  font-size: 12px;
  color: $primaryFontColor;
  font-weight: bold;
  margin-right: 4px;
}

.projectTPOName {
  font-family: $primaryFontFamily;
  font-size: 12px;
  color: $primaryFontColor;
  margin-top: 10px;
  line-height: 14px;
  cursor: pointer;
}

.projectData{
  max-width: 178px;
}

.projectCost {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.donateButton {
  background-color: $primaryColor;
  min-width: 75px;
  max-width: 150px;
  padding: 0 4px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  color: $light;
  font-family: $primaryFontFamily;
  font-size: $fontSubTitleSize;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.donateButton:hover {
  cursor: pointer;
  text-decoration: none;
}

.perTreeCost {
  font-family: $secondaryFontFamily;
  font-size: 10px;
  color: $primaryFontColor;
  margin-top: 5px;
  font-weight: 400;
}

.perTreeCost > span {
  font-family: $primaryFontFamily;
}
