@import './theme';
@import '~vizzuality-components/dist/bundle';

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
  padding-left: 20px;
}

// .mapboxgl-ctrl-bottom-right {
//   right: 80px;
// }

.mapboxgl-ctrl-attrib-inner > a {
  font-size: 10px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background: transparent;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: $primaryFontFamily !important;
}

.MuiFormControlLabel-root {
  margin: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  // background-color: #c8def4;
}

body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: var(--primary-font-family) !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  // background-color: #c8def4;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $primaryColor !important;
}

:focus {
  outline: none !important;
}

.planet-links {
  color: $primaryColor;
}

.planet-links:hover {
  color: $primaryColor;
}

.planet-links:visited {
  color: $primaryColor;
}

:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}

a {
  text-decoration: none;
}

// Reseting User agent styles to maintain cross browser interferences
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

// Deforestation slider
.rc-slider-mark-text {
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
  font-size: 12px;
}

// Stepper Connector
.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: $primaryColor !important;
}


@font-face {
  font-family: 'Pampers Script';
  src: url('/tenants/pampers/Pampers Script.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.primaryButton {
  color: white;
  font-weight: bold;
  background-color: #68b030;
  height: 40px;
  padding: 0px;
  text-align: center;
  border: 0px;
  border-radius: 52px;
  min-width: 150px;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
}
.primaryButton:hover{
  transform: translateY(-7px);
  cursor: pointer;
}

button{
  border: 0px;
  padding: 0px;
  margin: 0px;
  background: transparent;
}

@font-face {
  font-family: 'HarmoniaSansPro';
  src: url('/tenants/pampers/HarmoniaSansPro-SemiBd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}